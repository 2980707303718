import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { RecipePreviewType } from "~/types/recipe/RecipePreviewType";
import { timeSpentFormatter } from "~/utils/formatters/date.formatter";
import { HomeRecommendCategoryCollectionType } from "~/types/home/HomeRecommendCategoryCollectionType";
import { RecipeCategoryRecipeCollectionType } from "~/types/recipe/RecipeCategoryRecipeCollectionType";
import { RecipeDetailType } from "~/types/recipe/RecipeDetailType";

export const useHomeRecommendCategoryCollectionStore = defineStore({
    id: 'homeRecommendCategoryCollection',
    state: (): HomeRecommendCategoryCollectionType => ({
        list: undefined,
        error: undefined,
    }),
    getters: {},
    actions: {
        clear() {
            this.list = undefined;
            this.error = undefined;
        },

        async fetchList() {
            if (this.list !== undefined) {
                return true;
            }

            const config = useRuntimeConfig();

            try {
                const response = await useNuxtApp().axios.get('/home-page/recommend-categories');

                if (response?.data?.data === undefined) {
                    this.error = createError({ statusCode: 404, statusMessage: 'Page Not Found' });
                    return false;
                }

                if (!response?.data?.data.length) {
                    this.list = [];
                    return true;
                }

                this.list = response?.data?.data.map((item) => {
                    return <RecipeCategoryRecipeCollectionType>{
                        slug: item?.attributes?.slug,
                        title: item?.attributes?.title,
                        svgInline: item?.attributes?.svg_icon,
                        url: `/recipes/category-${item?.attributes?.slug}`,
                        recipes: item?.attributes?.recipes.map((recipe) => {
                            return <RecipePreviewType>{
                                slug: recipe.slug,
                                title: recipe.title,
                                prepTime: recipe.prep_time,
                                cookingTime: recipe.cooking_time,
                                totalTime: timeSpentFormatter(recipe.prep_time + recipe.cooking_time),
                                mainPicture: config.public.ssrMediaBaseUrl + recipe.main_picture?.url,
                                url: `/recipes/recipe-${recipe.slug}`
                            };
                        })
                    };
                });
                this.error = undefined;
            } catch (error) {
                console.log(error);
                this.error = createError({ statusCode: 500, statusMessage: 'Internal error' });
            }

            return true;
        }
    },
});
