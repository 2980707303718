<script setup lang="ts">
import { onMounted } from "vue";

const delayCheckVariableAvailabe = () => {
    if (!_isUndefined(window.adsbygoogle)) {
        setTimeout(() => {
            (window.adsbygoogle = window.adsbygoogle || []).push({})
        }, 50);
    } else {
        setTimeout(() => {
            delayCheckVariableAvailabe();
        }, 1000);
    }
}

onMounted(() => {
    delayCheckVariableAvailabe();
});
</script>

<template>
    <ins class="adsbygoogle"
         :key="Math.random()"
         style="display:block"
         data-ad-format="fluid"
         data-ad-layout-key="+28+s6-3-4z+93"
         data-ad-client="ca-pub-4873470855788285"
         :data-ad-region="`page-${Math.random()}`"
         data-ad-slot="6353170950"></ins>
</template>